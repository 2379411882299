import i18n, { FallbackLngObjList } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const namespace = 'translations';
export let initialized = false;
let currentLang = '';

const setLanguage = (lang: string, resolve: () => void) => {
  i18n.changeLanguage(lang, (err) => {
    if (err) {
      console.error(err);
    } else {
      currentLang = lang;
      resolve();
    }
  });
};

const requireTranslation = (langs: string[]) => {
  const locales = [];

  for (const lang of langs) {
    try {
      const locale = require(`../locales/${lang}/translations.json`);

      locales.push({
        locale,
        lang: lang,
      });
    } catch (e) {
      console.error(e);
    }
  }

  return locales;
};

const getSupportedLangs = (lang: string): string[] => {
  const langFallBack = {
    default: ['pt-BR'],
    en: ['pt-BR'],
    'en-US': ['pt-BR'],
    pt: ['pt-BR'],
  } as {
    default: string[];
    [key: string]: string[];
  };
  let fallbacks = langFallBack[lang] || [];
  const langs = lang ? [lang] : [];

  // Add 2 char fallbacks after 5 char fallbacks
  fallbacks = fallbacks.concat(langFallBack[lang.substr(0, 2)] || []);

  return [...new Set([...langs, ...fallbacks, ...langFallBack['default']])];
};

const handleChange = (lang: string, resolve: () => void) => {
  if (!initialized) {
    return;
  }

  if (lang === currentLang) {
    return;
  }

  const setLocales = requireTranslation(getSupportedLangs(lang));

  if (setLocales.length === 0) {
    console.log('Unable to find any valid translation resource');
  }

  for (const l of setLocales) {
    if (!i18n.hasResourceBundle(l.lang, namespace)) {
      i18n.addResourceBundle(l.lang, namespace, l.locale);
    }
  }

  setLanguage(setLocales[0].lang, resolve);
};

// const initComplete = () => {
//   initialized = true;
//   handleChange(i18n.language);
// };

export const init = () => {
  return new Promise<void>((resolve) => {
    const {
      langFallBack = { default: ['pt-BR'], en: ['pt-BR'], 'en-US': ['pt-BR'] },
      langWhiteList = ['pt-BR', 'en-US', 'en', 'pt'],
    } = {};

    // We append default to the end of all fallbacks for i18n key resolution
    const i18nLangFallback = Object.entries(langFallBack).reduce(
      (pv, [k, v]) => {
        const langs = [...v];

        if (langFallBack.default) {
          langFallBack.default.forEach((l: string) => {
            if (langs.indexOf(l) === -1) {
              langs.push(l);
            }
          });
        }

        pv[k] = langs;
        return pv;
      },
      {} as FallbackLngObjList
    );

    i18n.use(LanguageDetector).init(
      {
        cleanCode: true,
        debug: process.env.NODE_ENV !== 'production',
        defaultNS: namespace,
        detection: {
          caches: [],
          order: ['querystring', 'navigator'],
        },
        fallbackLng: i18nLangFallback,
        interpolation: {
          escapeValue: false,
        },
        nonExplicitSupportedLngs: true,
        ns: [namespace],
        supportedLngs: langWhiteList.length > 0 ? langWhiteList : false,
      },
      () => {
        initialized = true;
        handleChange(i18n.language, resolve);
      }
    );
  });
};

export default i18n;
