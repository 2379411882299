import React from 'react';

import { Turnstile } from '@marsidev/react-turnstile';

import { verifyTurnstile } from '../services/auth';

type Props = {
  onSuccess: (token: string) => void;
  onError: () => void;
};

const TurnstileComponent: React.FC<Props> = ({ onSuccess, onError }) => {
  const siteKey =
    process.env.NODE_ENV === 'development'
      ? '1x00000000000000000000AA'
      : '0x4AAAAAAAV2DiaLHXedNjwI';

  return (
    <Turnstile
      siteKey={siteKey}
      options={{ theme: 'light' }}
      onSuccess={async (token) => {
        try {
          if (process.env.NODE_ENV !== 'development') {
            await verifyTurnstile(token);
          }
          onSuccess(token);
        } catch (error) {
          console.log('ERROR>>>>>>', error);
          onError();
        }
      }}
      onError={onError}
    />
  );
};

export default TurnstileComponent;
