import React, { useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { registerUser } from '../services/user';

import { useNavigate } from 'react-router-dom';

import { Modal, Spinner } from 'react-bootstrap';

import PasswordRequirements from '../components/PasswordRequirements';
import ErrorModal from '../modals/ErrorModal';
import i18n from '../utils/i18n';

type FormValues = {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
};

const Register = () => {
  const navigate = useNavigate();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [passwordRequirementsMet, setPasswordRequirementsMet] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('CreateAccount.form.errors.emailAddressValidEmail'))
      .required(i18n.t('CreateAccount.form.errors.required')),
    username: Yup.string().required(
      i18n.t('CreateAccount.form.errors.required')
    ),
    password: Yup.string().required(
      i18n.t('CreateAccount.form.errors.required')
    ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        i18n.t('CreateAccount.form.errors.passwordMustMatch')
      )
      .required(i18n.t('CreateAccount.form.errors.required'))
      .trim(),
  });

  const [errorModal, setErrorModal] = useState<{
    isOpen: boolean;
    message?: string;
  }>({
    isOpen: false,
    message: '',
  });

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
      await registerUser(
        values.username,
        values.email.toLowerCase(),
        values.password
      );
      setShowConfirmationModal(true);
    } catch (error) {
      if (error.response?.status === 409) {
        setErrorModal({
          isOpen: true,
          message: `${error.response.data} Please, try a new one.`,
        });
        return;
      }
      setErrorModal({
        isOpen: true,
        message: `There was a problem with the registration. Please, try again.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const onCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    navigate('/login');
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
    initialErrors: {
      email: i18n.t('CreateAccount.form.errors.required'),
      password: i18n.t('CreateAccount.form.errors.required'),
      confirmPassword: i18n.t('CreateAccount.form.errors.required'),
    },
  });

  return (
    <>
      <div className="main-wrap">
        <div className="row">
          <div className="col-xl-5 justify-content-center align-items-center d-flex">
            <img
              src="assets/images/logo-login.png"
              className="login-logo"
              alt=""
            />
          </div>
          <div className="col-xl-7 login-block align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-4">
                  {i18n.t<string>('CreateAccount.title')}
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm feather-at-sign text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      className="style2-input ps-5 form-control text-grey-900 font-xss fw-600"
                      placeholder={i18n.t<string>(
                        'CreateAccount.form.username'
                      )}
                      autoCapitalize="none"
                      id="username"
                      onChange={(e) => {
                        setFieldValue('username', e.target.value.toLowerCase());
                      }}
                      onBlur={handleBlur}
                      value={values.username}
                      disabled={loading}
                    />
                    <span className="text-danger font-xsss fw-500">
                      {touched.username && errors.username
                        ? errors.username
                        : ''}
                    </span>
                  </div>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      className="style2-input ps-5 form-control text-grey-900 font-xss fw-600"
                      placeholder={i18n.t<string>('CreateAccount.form.email')}
                      id="email"
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      disabled={loading}
                    />
                    <span className="text-danger font-xsss fw-500">
                      {touched.email && errors.email ? errors.email : ''}
                    </span>
                  </div>
                  <div className="form-group mb-2 d-flex flex-row align-items-center">
                    <i
                      className="font-sm ti-lock text-grey-500 pe-0"
                      style={{
                        position: 'absolute',
                        left: 30,
                      }}
                    />
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="style2-input px-5 form-control text-grey-900 font-xss ls-3"
                      placeholder={i18n.t<string>(
                        'CreateAccount.form.password'
                      )}
                      id="password"
                      autoCapitalize="none"
                      onChange={(e) => {
                        const text = e.target.value;
                        setFieldValue('password', text.trim());
                      }}
                      onBlur={handleBlur}
                      disabled={loading}
                      value={values.password}
                    />
                    <i
                      className={`font-sm ${
                        showPassword ? 'feather-eye' : 'feather-eye-off'
                      } text-grey-500 pe-0`}
                      style={{
                        position: 'absolute',
                        right: 30,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  </div>
                  <PasswordRequirements
                    password={values.password}
                    onMeetAllRequirements={() => {
                      setPasswordRequirementsMet(true);
                    }}
                  />
                  <div className="form-group mt-3 d-flex flex-row align-items-center">
                    <i
                      className="font-sm ti-lock text-grey-500 pe-0"
                      style={{
                        position: 'absolute',
                        left: 30,
                      }}
                    />
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="style2-input px-5 form-control text-grey-900 font-xss ls-3"
                      placeholder={i18n.t<string>(
                        'CreateAccount.form.confirmPassword'
                      )}
                      id="confirmPassword"
                      autoCapitalize="none"
                      onChange={(e) => {
                        const text = e.target.value;
                        setFieldValue('confirmPassword', text.trim());
                      }}
                      onBlur={handleBlur}
                      disabled={loading}
                      value={values.confirmPassword}
                    />
                    <i
                      className={`font-sm ${
                        showConfirmPassword ? 'feather-eye' : 'feather-eye-off'
                      } text-grey-500 pe-0`}
                      style={{
                        position: 'absolute',
                        right: 30,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    />
                  </div>
                  <span className="text-danger font-xsss mb-2 fw-500">
                    {touched.confirmPassword && errors.confirmPassword
                      ? errors.confirmPassword
                      : ''}
                  </span>
                </form>
                <div className="mb-4 mt-3 border-0 bg-transparent">
                  <p className="font-xsss mt-1">
                    {i18n.t<string>('CreateAccount.form.acceptTermsText1')}{' '}
                    <span
                      className="text-warning fw-700"
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        window
                          .open(
                            'https://pages.silksecret.me/docs/termos',
                            '_blank'
                          )
                          .focus();
                      }}
                    >
                      {i18n.t<string>('CreateAccount.form.acceptTermsText2')}
                    </span>{' '}
                    {i18n.t<string>('CreateAccount.form.acceptTermsText3')}{' '}
                    <span
                      className="text-warning fw-700"
                      onClick={() => {
                        window
                          .open(
                            'https://pages.silksecret.me/docs/privacidade',
                            '_blank'
                          )
                          .focus();
                      }}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                    >
                      {i18n.t<string>('CreateAccount.form.acceptTermsText4')}
                    </span>
                    {' '}
                    {i18n.t<string>('CreateAccount.form.acceptTermsText5')}
                  </p>
                </div>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="text-warning"
                    />
                  </div>
                ) : (
                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1">
                      <button
                        onClick={() => handleSubmit()}
                        disabled={!isValid || !passwordRequirementsMet}
                        className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
                        style={
                          !isValid || !passwordRequirementsMet
                            ? { opacity: 0.5 }
                            : { opacity: 1 }
                        }
                      >
                        {i18n.t<string>('CreateAccount.button')}
                      </button>
                    </div>
                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                      {i18n.t<string>('CreateAccount.alreadyHaveAccount')}
                      <a href="/login" className="fw-700 ms-1 text-warning">
                        {i18n.t<string>('CreateAccount.login')}
                      </a>
                    </h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        isOpen={errorModal.isOpen}
        message={errorModal.message}
        onClose={() => {
          setErrorModal({ isOpen: false });
        }}
      />
      <Modal show={showConfirmationModal} onHide={onCloseConfirmationModal}>
        <Modal.Header
          closeButton
          onClick={onCloseConfirmationModal}
          className="bg-white"
          style={{
            border: 0,
            borderRadius: 0,
            padding: 20,
            borderTopLeftRadius: 10,
            WebkitBorderTopRightRadius: 10,
          }}
        />
        <Modal.Body
          className="bg-white"
          style={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <p className="font-xs text-black">
            {i18n.t<string>('CreateAccount.confirmationMessage', {
              email: values.email,
            })}
          </p>
          <div className="form-group mb-1">
            <button
              onClick={onCloseConfirmationModal}
              disabled={!isValid}
              className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
              style={!isValid ? { opacity: 0.5 } : { opacity: 1 }}
            >
              {i18n.t<string>('Global.ok')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;
