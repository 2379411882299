import React, { useState } from 'react';

import Header from '../components/Header';

import './Payment.scss';

// import Cards from 'react-credit-cards-2';

// import 'react-credit-cards-2/dist/es/styles-compiled.css';
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
} from '../utils/data';
import CardTypes from '../enums/CardTypes';
import { Modal } from 'react-bootstrap';
import LeftMenu from '../components/LeftMenu';

const Payment = () => {
  const [displayNewCardForm, setDisplayNewCardForm] = useState(false);
  const [showDeleteCardConfirmationModal, setShowDeleteCardConfirmationModal] =
    useState(false);
  const [registeredCard] = useState<RegisteredCreditCard | null>({
    lastNumbers: '23',
    issuer: CardTypes.visa,
  });
  const [newCardForm, setNewCardForm] = useState<CreditCardForm>({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
  });

  const handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    setNewCardForm((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const handleInputFocus = (evt) => {
    setNewCardForm((prev) => ({ ...prev, focus: evt.target.name }));
  };

  return (
    <>
      <Header />
      <LeftMenu />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    Payment Method
                  </h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="col-lg-12 ps-0">
                        <h4 className="mb-4 font-lg fw-700 mont-font mb-5">
                          Saved Credit Card
                        </h4>
                      </div>
                      {registeredCard ? (
                        <div className="card border-0 mb-4 shadow-none">
                          <div className="card-body d-block text-left p-0">
                            <div
                              className={`item w-100 h150 bg-primary rounded-xxl text-left shadow-md ps-3 pt-2 align-items-end flex-column d-flex`}
                            >
                              <div className="card bg-transparent border-0 bg-transparent-card shadow-none p-0 text-left w-100">
                                <div className="row justify-content-between">
                                  <div
                                    className={`col-6 card__issuer card__issuer--${registeredCard.issuer}`}
                                  />
                                  <div className="col-6 text-right pe-4">
                                    <img
                                      src="assets/images/chip.png"
                                      alt="icon"
                                      className="w30 float-right d-inline-block mt-2 me-2 rounded-3"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="card bg-transparent border-0 bg-transparent-card shadow-none p-0 text-left w-100 mt-auto">
                                <h4 className="text-white mb-3 font-sm fw-700 mont-font">
                                  {` ***** ${registeredCard.lastNumbers}`}
                                  <span className="d-block fw-500 text-grey-300 font-xssss mt-1">
                                    Credit Card
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </div>
                          <button
                            className="bg-transparent border-0 mt-2"
                            onClick={() => {
                              setShowDeleteCardConfirmationModal(true);
                            }}
                          >
                            <i
                              className="feather-trash-2 font-md align-self-center text-danger"
                              style={{
                                cursor: 'pointer',
                              }}
                            />
                          </button>
                        </div>
                      ) : (
                        <div className="mb-5 w-100 d-flex flex-column justify-content-center align-items-center">
                          <i className="feather-credit-card display5-size text-grey-400"></i>
                          <span className="text-grey-500 font-xss">
                            No credit card registered
                          </span>
                        </div>
                      )}
                      {/* <button
                        onClick={() => {
                          setDisplayNewCardForm(true);
                        }}
                        className="rounded-xxl border-dashed mb-2 p-3 w-100 fw-600 fw-700 text-center font-xssss mont-font text-uppercase ls-3 d-block text-warning bg-transparent"
                      >
                        Add Card
                      </button> */}
                      <button
                        onClick={() => {
                          setDisplayNewCardForm(true);
                        }}
                        className="rounded-3 bg-current mb-2 mt-5 p-3 w-100 fw-600 fw-700 text-center font-xssss mont-font text-uppercase ls-3 text-white d-block border-0 bg-warning"
                      >
                        {registeredCard ? 'Change Card' : 'New Card'}
                      </button>
                    </div>
                    {displayNewCardForm && (
                      <div className="col-lg-6 offset-lg-1">
                        <div className="d-flex w-100 mb-1 d-flex justify-content-end">
                          <i
                            onClick={() => setDisplayNewCardForm(false)}
                            className="font-xl text-dark feather-x"
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                        <div className="rounded-xxl bg-greylight h-100 p-3">
                          <div className="col-lg-12">
                            <form>
                              <div className="form-group mb-1">
                                <label className="text-dark-color text-grey-600 font-xssss mb-2 fw-600">
                                  Card Number
                                </label>
                                <div className="form-group icon-tab">
                                  <input
                                    name="number"
                                    type="tel"
                                    className="bg-white font-xsss border-0 rounded-3 form-control ps-4 bg-color-none border-bottom text-grey-900"
                                    value={newCardForm.number}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    placeholder="Card Number"
                                  />
                                </div>
                              </div>
                              <div className="form-group mb-1">
                                <label className="text-dark-color text-grey-600 font-xssss mb-2 fw-600">
                                  Card Holder Name
                                </label>
                                <div className="form-group icon-tab">
                                  <input
                                    type="text"
                                    name="name"
                                    className="bg-white border-0 rounded-3 form-control ps-4 bg-color-none border-bottom text-grey-900"
                                    placeholder="Name"
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="form-group mb-1">
                                <label className="text-dark-color text-grey-600 font-xssss mb-2 fw-600">
                                  Expiration Date
                                </label>
                                <div className="form-group icon-tab">
                                  <input
                                    type="tel"
                                    name="expiry"
                                    className="bg-white border-0 rounded-3 form-control ps-4 bg-color-none border-bottom text-grey-900"
                                    placeholder="Expiration Date"
                                    pattern="\d\d/\d\d"
                                    required
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <button
                                    onClick={() => false}
                                    className="rounded-3 bg-current mb-2 mt-4 p-3 w-100 fw-600 fw-700 text-center font-xssss mont-font text-uppercase ls-3 text-white d-block border-0 bg-warning"
                                  >
                                    Save Card
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showDeleteCardConfirmationModal}>
        <Modal.Header
          closeButton
          onClick={() => setShowDeleteCardConfirmationModal(false)}
          className="bg-white"
          style={{
            border: 0,
            borderRadius: 0,
            padding: 20,
            borderTopLeftRadius: 10,
            WebkitBorderTopRightRadius: 10,
          }}
        />
        <Modal.Body className="bg-white">
          <p className="font-xs text-black text-center">
            Do you want to remove the credit card?
          </p>
        </Modal.Body>
        <Modal.Footer className="bg-white d-flex flex-row">
          <div className="form-group mb-1 flex-grow-1">
            <button
              onClick={() => setShowDeleteCardConfirmationModal(false)}
              className="form-control text-center text-white fw-600 bg-dark border-0 p-0"
            >
              Cancel
            </button>
          </div>
          <div className="form-group mb-1 flex-grow-1">
            <button
              onClick={() => {
                console.log('delete card');
              }}
              className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Payment;
