import React, { useEffect, useState } from 'react';

import ProfileBanner from './ProfileBanner';
import { follow, unfollow } from '../services/user';
import i18n from '../utils/i18n';

type Props = {
  profile: Profile;
  plan: Plan;
  creatorId?: string;
  onSubscribe: () => void;
};

const ProfileCard: React.FC<Props> = ({
  profile,
  creatorId,
  plan,
  onSubscribe,
}) => {
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    if (profile.followed) {
      setIsFollowing(profile.followed);
    }
  }, [profile.followed]);

  const onFollow = async () => {
    try {
      setIsFollowing(!isFollowing);
      await follow(creatorId);
    } catch (error) {
      console.log(error);
    }
  };

  const onUnfollow = async () => {
    try {
      setIsFollowing(!isFollowing);
      await unfollow(creatorId);
    } catch (error) {
      console.log(error);
    }
  };

  const renderSubscriptionButton = () => {
    if (!plan.value) return null;

    return (
      <button
        onClick={onSubscribe}
        className="btn p-2 m-2 rounded-md bg-warning font-xssss fw-700 text-white profile-card-buttons-subscriptions-buttons"
      >
        {i18n.t<string>('ProfileCard.subscribe', { value: plan.value / 100 })}
      </button>
    );
  };

  return (
    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
      <ProfileBanner profile={profile} />
      <div className="card-body p-0 position-relative">
        <figure
          className="avatar position-absolute w100 z-index-1"
          style={{ top: '-40px', left: '30px' }}
        >
          <img
            src={`${
              profile.avatar ? profile.avatar : 'assets/images/user-100x100.png'
            }`}
            alt="avater"
            className="float-right p-1 bg-white rounded-circle w-100"
            style={{
              width: 100,
              height: 100,
            }}
          />
        </figure>
        <div className="d-flex flex-column">
          <h4 className="fw-700 font-sm mt-2 pl-15">{profile.username}</h4>
          <h4 className="fw-500 font-xssse mb-5 pl-15">{`@${profile.username}`}</h4>
        </div>
      </div>
      <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
        <div className="d-flex justify-content-between align-items-center profile-card-buttons">
          <div className="px-2 py-2">
            <img
              src="assets/images/instagram-logo.png"
              style={{
                maxHeight: 35,
                maxWidth: 35,
                cursor: 'pointer',
                marginRight: 10,
              }}
              alt=""
              onClick={() =>
                window.open('https://www.instagram.com/', '_blank').focus()
              }
            />
            <img
              src="assets/images/x-logo.png"
              style={{
                maxHeight: 25,
                maxWidth: 25,
                cursor: 'pointer',
                marginRight: 10,
              }}
              alt=""
              onClick={() => window.open('https://x.com/', '_blank').focus()}
            />
            <img
              src="assets/images/tiktok.png"
              style={{
                maxHeight: 25,
                maxWidth: 25,
                cursor: 'pointer',
                marginRight: 8,
              }}
              alt=""
              onClick={() =>
                window.open('https://tiktok.com/', '_blank').focus()
              }
            />
          </div>
          <div className="d-flex profile-card-buttons-subscriptions">
            {renderSubscriptionButton()}
            {!isFollowing ? (
              <button
                onClick={onFollow}
                className="btn p-2 m-2 rounded-md bg-warning font-xssss fw-700 text-white profile-card-buttons-subscriptions-buttons"
              >
                {i18n.t<string>('ProfileCard.follow')}
                <i
                  className={`feather-user-plus font-xsss text-white fw-800`}
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                />
              </button>
            ) : (
              <button
                onClick={onUnfollow}
                className="btn p-2 m-2 rounded-md bg-warning font-xssss fw-700 text-white profile-card-buttons-subscriptions-buttons"
              >
                {i18n.t<string>('ProfileCard.following')}
                <i
                  className={`feather-user-check font-xsss text-white fw-800`}
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
