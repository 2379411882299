import React, { useEffect, useMemo } from 'react';
import i18n from '../utils/i18n';

type Requirement = {
  requirement: string;
  regex: string;
  match?: boolean;
};

const data: Requirement[] = [
  {
    requirement: '1',
    regex: '(^.{12,}$)',
  },
  {
    requirement: '2',
    regex: '(?=.*[a-z])(?=.*[A-Z])',
  },
  {
    requirement: '3',
    regex: '[!?@#$%^&*)(+=._-]',
  },
];

type Props = {
  password: string;
  onMeetAllRequirements: () => void;
};

const PasswordRequirements: React.FC<Props> = ({
  password,
  onMeetAllRequirements,
}) => {
  const requirements: Requirement[] = useMemo(() => {
    if (!password) {
      return data;
    }

    return data.map((identity) => {
      const match = new RegExp(identity.regex).test(password.trim());

      return { match, ...identity };
    });
  }, [password]);

  useEffect(() => {
    const existsRemainingRequirements = requirements.find(
      (requirement) => !requirement.match
    );

    if (!existsRemainingRequirements) {
      onMeetAllRequirements();
    }
  }, [requirements, onMeetAllRequirements]);

  return (
    <div>
      {requirements.map((req) => {
        return (
          <div className="d-flex flex-row" key={req.requirement}>
            <i
              className="font-md feather-check-circle me-2 mt-1"
              style={req.match ? { color: 'green' } : { color: 'black' }}
            />
            <span>{i18n.t<string>(`PasswordRules.${req.requirement}`)}</span>
          </div>
        );
      })}
    </div>
  );
};

export default PasswordRequirements;
