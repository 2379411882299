import React, { useCallback, useEffect, useState } from 'react';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import ProfileDetail from '../components/ProfileDetail';
import ProfileCard from '../components/ProfileCard';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { session } from '../signals/session';
import FeedScrollPage from '../components/Utility/FeedScrollPage';
import Feed from '../components/Utility/Feed';
import LeftMenu from '../components/LeftMenu';
import useFeed from '../hooks/useFeed';
import i18n from '../utils/i18n';
// import SubscribeCard from '../components/SubscribeCard';
import SubscriptionModal from '../modals/SubscriptionModal';
// import { subscribe } from '../services/creator';

const UserPage = () => {
  const navigate = useNavigate();

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const location = useLocation();

  const {
    feed,
    plan,
    isFeedLoading,
    isFeedNextPageLoading,
    loadPage,
    onLikePost,
    fetchFeedNextPage,
  } = useFeed();

  useEffect(() => {
    const username = location.pathname.replace('/', '');
    if (username === session.value.username) {
      navigate('/myprofile');
      return;
    }
    loadPage(username);
  }, [loadPage, location.pathname, navigate]);

  const onReachOutBottom = () => {
    const username = location.pathname.replace('/', '');
    fetchFeedNextPage(username);
  };

  const onSubscribe = useCallback(async () => {
    setShowSubscriptionModal(true);
  }, []);

  return (
    <>
      <Header />
      <LeftMenu />
      {isFeedLoading ? (
        <div
          className="align-self-center d-flex flex-column justify-content-center align-items-center"
          style={{
            marginTop: 150,
          }}
        >
          <Spinner animation="border" role="status" className="text-warning" />
        </div>
      ) : (
        <FeedScrollPage
          loading={isFeedLoading}
          footerLoading={isFeedNextPageLoading}
          onReachOutBottom={onReachOutBottom}
          feed={feed}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12 mb-3">
                  <ProfileCard
                    plan={plan}
                    profile={feed.profile}
                    creatorId={feed.creatorId}
                    onSubscribe={onSubscribe}
                  />
                </div>
                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                  <ProfileDetail profile={feed.profile} />
                </div>
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  <div className="card w-100 shadow-xss rounded-xxl border-0 d-flx flex-row justify-content-between align-items-center p-3 mb-3">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <i className="font-sm feather-lock mb-2 text-grey-500"></i>
                      <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                        {i18n.t<string>('Userpage.posts', { number: feed.totalPosts })}
                      </h4>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <i className="font-sm feather-image mb-2 text-grey-500"></i>
                      <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                        {i18n.t<string>('Userpage.photos', { number: 0 })}
                      </h4>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <i className="font-sm feather-film mb-2 text-grey-500"></i>
                      <h4 className="fw-600 text-grey-600 font-xsss mb-0 mt-0">
                        {i18n.t<string>('Userpage.videos', { number: 0 })}
                      </h4>
                    </div>
                  </div>
                  <Feed
                    isNextPageLoading={isFeedNextPageLoading}
                    feed={feed}
                    onLikePost={onLikePost}
                  />
                </div>
              </div>
            </div>
          </div>
        </FeedScrollPage>
      )}
      <SubscriptionModal
        isVisible={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
        plan={plan}
      />
      <Appfooter />
    </>
  );
};

export default UserPage;
