import React, { useRef, useState } from 'react';

import { Modal, Spinner } from 'react-bootstrap';
import { profile } from '../../signals/profile';
import { getImageUploadUrl, uploadMedia } from '../../services/post';
import { updateCover } from '../../services/user';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onSave: (banner: any) => void;
};

const ProfileBannerModal: React.FC<Props> = ({
  isVisible,
  onClose,
  onSave,
}) => {
  const [loading, setLoading] = useState(false);

  const inputFile = useRef(null);

  const [showSaveButton, setShowSaveButton] = useState(false);

  const [newPhoto, setNewPhoto] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setNewPhoto(imageUrl);
      setShowSaveButton(true);
    }
  };

  const onSaveBanner = async () => {
    try {
      setLoading(true);
      const resp = await fetch(newPhoto);
      const blob = await resp.blob();

      const mediaUploadUrl = await getImageUploadUrl();
      const uploadMediaResponse = await uploadMedia(mediaUploadUrl, blob);

      await updateCover(uploadMediaResponse.id);

      profile.value.cover = newPhoto;

      setShowSaveButton(false);

      onCloseModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onCloseModal = () => {
    onClose();
    setShowSaveButton(false);
    setNewPhoto(null);
  };

  const getPhotoPreview = () => {
    if (newPhoto) {
      return newPhoto;
    }
    return profile.value.cover
      ? profile.value.cover
      : 'assets/images/banner-1200x250.png';
  };

  return (
    <Modal show={isVisible} size="lg">
      <Modal.Header
        closeButton
        onClick={onCloseModal}
        className="bg-white"
        style={{
          border: 0,
          borderRadius: 0,
          padding: 20,
          borderTopLeftRadius: 10,
          WebkitBorderTopRightRadius: 10,
        }}
      >
        <h1 className="fw-700 text-grey-900 font-md">Capa do Perfil</h1>
      </Modal.Header>
      <Modal.Body className="d-flex bg-white justify-content-center align-items-center flex-column">
        <div className="card border-0 w-100">
          {loading ? (
            <Spinner
              animation="border"
              role="status"
              className="text-warning align-self-center m-5"
            />
          ) : (
            <img
              src={getPhotoPreview()}
              alt="avatar"
              style={{
                margin: 10,
                borderRadius: 10,
                maxHeight: 250,
              }}
            />
          )}
          <input
            type="file"
            id="file"
            ref={inputFile}
            multiple
            onChange={handleImageChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-white d-flex justify-content-center">
        <div className="d-flex flex-row">
          {showSaveButton && (
            <button
              onClick={onSaveBanner}
              disabled={loading}
              className="d-flex align-items-center justify-content-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-transparent"
              style={{ cursor: 'pointer', opacity: loading ? 0.5 : 1 }}
            >
              <i className="font-md text-warning feather-save me-2"></i>
              <span>Salvar</span>
            </button>
          )}
          <button
            onClick={() => {
              inputFile.current.click();
            }}
            disabled={loading}
            className="d-flex w-100 align-items-center justify-content-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 bg-transparent border-0"
            style={{ cursor: 'pointer', opacity: loading ? 0.5 : 1 }}
          >
            <i className="font-md text-warning feather-image me-2"></i>
            <span>
              {!profile.value.cover && !newPhoto ? 'Adicionar Foto' : 'Mudar Foto'}
            </span>
          </button>
          <input
            type="file"
            id="file"
            ref={inputFile}
            multiple
            onChange={handleImageChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileBannerModal;
