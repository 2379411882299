import React, { useState } from 'react';

import { Modal, Spinner } from 'react-bootstrap';
import TextArea from './TextArea';

import { profile } from '../signals/profile';
import { updateAbout } from '../services/user';
import i18n from '../utils/i18n';

const MyProfileDetail = () => {
  const [showEditModal, setEditModal] = useState(false);
  const [about, setAbout] = useState(profile.value.about);
  const [loading, setLoading] = useState(false);

  const onSave = async () => {
    try {
      setLoading(true);

      await updateAbout(about);
      profile.value.about = about;

      setEditModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">
          {i18n.t<string>('MyProfileDetail.about')}
        </h4>
        <p className="fw-500 lh-24 font-xssss mb-0">{about}</p>
      </div>
      <div
        onClick={() => {
          setEditModal(true);
        }}
        className="d-flex align-self-center font-xssss fw-600 ls-1 text-grey-700 text-dark mb-3"
        style={{ cursor: 'pointer' }}
      >
        <i className="font-md text-warning feather-edit me-2"></i>
        <span>{i18n.t<string>('MyProfileDetail.edit')}</span>
      </div>
      <Modal show={showEditModal} onHide={() => setEditModal(false)}>
        <Modal.Header
          closeButton
          onClick={() => setEditModal(false)}
          className="bg-white"
          style={{
            border: 0,
            borderRadius: 0,
            padding: 20,
            borderTopLeftRadius: 10,
            WebkitBorderTopRightRadius: 10,
          }}
        >
          <h1 className="fw-700 text-grey-900 font-md">{i18n.t<string>('MyProfileDetail.about')}</h1>
        </Modal.Header>
        <Modal.Body className="bg-white d-flex flex-column">
          {loading ? (
            <Spinner
              animation="border"
              role="status"
              className="text-warning align-self-center"
            />
          ) : (
            <TextArea
              onChangeText={setAbout}
              initialValue={profile.value.about}
              placeholder={i18n.t<string>('MyProfileDetail.placeholder')}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="bg-white justify-content-center">
          <button
            onClick={onSave}
            disabled={loading}
            className="d-flex align-items-center justify-content-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-transparent"
            style={{ cursor: 'pointer' }}
          >
            <i className="font-md text-warning feather-save me-2"></i>
            <span>{i18n.t<string>('MyProfileDetail.save')}</span>
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyProfileDetail;
