import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import Createpost from '../components/Createpost';
import MyProfileCard from '../components/MyProfileCard';
import MyProfileDetail from '../components/MyProfileDetail';
import FeedScrollPage from '../components/Utility/FeedScrollPage';
import Feed from '../components/Utility/Feed';
import LeftMenu from '../components/LeftMenu';
import useFeed from '../hooks/useFeed';
import { session } from '../signals/session';
import { profile } from '../signals/profile';
import BecomeCreatorBanner from '../components/BecomeCreatorBanner';

const MyProfile = () => {
  const [isCreator, setIsCreator] = useState(true);

  const {
    feed,
    isFeedLoading,
    isFeedNextPageLoading,
    loadPage,
    onDeletePost,
    onPost,
    fetchFeedNextPage,
  } = useFeed(true);

  useEffect(() => {
    loadPage(session.value.username);
    setIsCreator(profile.value.creatorId ? true : false);
  }, [loadPage]);

  const onReachOutBottom = () => {
    fetchFeedNextPage(session.value.username);
  };

  return (
    <>
      <Header />
      <LeftMenu />
      <FeedScrollPage
        loading={isFeedLoading}
        footerLoading={isFeedNextPageLoading}
        onReachOutBottom={onReachOutBottom}
        feed={feed}
      >
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <MyProfileCard />
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                <MyProfileDetail />
              </div>
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {isCreator ? (
                  <>
                    <Createpost onPost={onPost} />
                    <Feed
                      isLoading={isFeedLoading}
                      isNextPageLoading={isFeedNextPageLoading}
                      feed={feed}
                      onDeletePost={onDeletePost}
                    />
                  </>
                ) : (
                  <div className='mb-5 pb-5'>
                    <BecomeCreatorBanner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </FeedScrollPage>
      <Appfooter />
    </>
  );
};

export default MyProfile;
