import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';

import { signIn } from '../services/auth';
import { getProfile } from '../services/user';

import { session } from '../signals/session';
import { profile } from '../signals/profile';

import { persistAuthSession, scheduleRefreshToken } from '../utils/session';
import TurnstileComponent from '../components/TurnstileComponent';
import i18n from '../utils/i18n';

type FormValues = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [showLoginError, setShowLoginError] = useState(false);

  const [isTurnstileSuccess, setIsTurnstileSuccess] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('Login.form.errors.emailAddressValidEmail'))
      .required(i18n.t('Login.form.errors.required')),
    password: Yup.string().required(i18n.t('Login.form.errors.required')),
  });

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);

      const { token, refreshToken } = await signIn(
        values.email.toLowerCase(),
        values.password
      );

      const { userId, exp, username } = jwtDecode<JWT>(token);

      session.value.token = token;
      session.value.refreshToken = refreshToken;
      session.value.userId = userId;
      session.value.username = username;

      persistAuthSession({
        token,
        refreshToken,
        userId,
        username,
        expires: exp,
      });

      scheduleRefreshToken();

      const profileResp = await getProfile();
      profile.value = profileResp;

      navigate(profile.value.creatorId ? '/myprofile' : '/');
    } catch (error) {
      setShowLoginError(true);
    } finally {
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  return (
    <>
      <div className="main-wrap">
        <div className="row">
          <div className="col-xl-5 justify-content-center align-items-center d-flex">
            <img
              src="assets/images/logo-login.png"
              className="login-logo"
              alt=""
            />
          </div>
          <div className="col-xl-7 login-block align-items-center justify-content-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-3">
                  {i18n.t<string>('Login.title')}
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      id="email"
                      autoCapitalize="none"
                      className="style2-input ps-5 form-control text-grey-900 font-xss fw-600"
                      placeholder={i18n.t<string>('Login.form.email')}
                      inputMode="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                    />
                    <span className="text-danger font-xsss fw-500">
                      {touched.email && errors.email ? errors.email : ''}
                    </span>
                  </div>
                  <div className="form-group mb-2 d-flex flex-row align-items-center">
                    <i
                      className="font-sm ti-lock text-grey-500 pe-0"
                      style={{
                        position: 'absolute',
                        left: 30,
                      }}
                    />
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="style2-input px-5 form-control text-grey-900 font-xss ls-3"
                      placeholder={i18n.t<string>('Login.form.password')}
                      id="password"
                      autoCapitalize="none"
                      onChange={(e) => {
                        const text = e.target.value;
                        setFieldValue('password', text.trim());
                      }}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <i
                      className={`font-sm ${
                        showPassword ? 'feather-eye' : 'feather-eye-off'
                      } text-grey-500 pe-0`}
                      style={{
                        position: 'absolute',
                        right: 30,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  </div>
                  <span className="text-danger font-xsss fw-500">
                      {touched.password && errors.password ? errors.password : ''}
                    </span>
                  <div className="form-check text-center mb-4">
                    <a
                      href="/forgot"
                      className="fw-600 font-xsss text-grey-700 mt-1"
                    >
                      {i18n.t<string>('Login.forgot')}
                    </a>
                  </div>
                </form>
                {!isTurnstileSuccess && (
                  <div className="d-flex justify-content-center mb-4">
                    <TurnstileComponent
                      onSuccess={() => setIsTurnstileSuccess(true)}
                      onError={() => setIsTurnstileSuccess(false)}
                    />
                  </div>
                )}
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="text-warning"
                    />
                  </div>
                ) : (
                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1">
                      <button
                        onClick={() => handleSubmit()}
                        disabled={!isValid || !isTurnstileSuccess}
                        className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
                        style={
                          !isValid || !isTurnstileSuccess
                            ? { opacity: 0.5 }
                            : { opacity: 1 }
                        }
                      >
                        {i18n.t<string>('Login.button')}
                      </button>
                    </div>
                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                      {i18n.t<string>('Login.noAccount')}
                      <a href="/register" className="fw-700 ms-1 text-warning">
                        {i18n.t<string>('Login.register')}
                      </a>
                    </h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showLoginError}>
        <Modal.Header
          closeButton
          onClick={() => setShowLoginError(false)}
          className="bg-white"
          style={{
            border: 0,
            borderRadius: 0,
            padding: 20,
            borderTopLeftRadius: 10,
            WebkitBorderTopRightRadius: 10,
          }}
        />
        <Modal.Body
          className="bg-white"
          style={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <p className="font-xs text-black">
            {i18n.t<string>('Login.loginFailure')}
          </p>
          <div className="form-group mb-1">
            <button
              onClick={() => setShowLoginError(false)}
              disabled={!isValid}
              className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
              style={!isValid ? { opacity: 0.5 } : { opacity: 1 }}
            >
              {i18n.t<string>('Global.ok')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
