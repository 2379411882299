import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import { useNavigate } from 'react-router-dom';
import LeftMenu from '../components/LeftMenu';
import { getFollowers } from '../services/feed';
import { Spinner } from 'react-bootstrap';
import i18n from '../utils/i18n';

type Data = {
  id: string;
  userId: string;
  avatar: string;
  username: string;
};

const Following = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Data[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const items = await getFollowers();
        setItems(items);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, []);

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            {loading ? (
              <div
                className="align-self-center d-flex flex-column justify-content-center align-items-center"
                style={{
                  padding: 100,
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  className="text-warning"
                />
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-12">
                  <div className="card-body p-4 w-100 bg-white border-0 d-flex rounded-3">
                    <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                      {i18n.t<string>('Following.title', {
                        number: items.length,
                      })}
                    </h4>
                  </div>
                  <div className="row ps-2 pe-2">
                    {items.length ? (
                      items.map((value, index) => (
                        <div
                          onClick={() => navigate(`/${value.username}`)}
                          key={index}
                          className="col-md-3 col-sm-4 pe-2 ps-2"
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                            <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                              <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                                <img
                                  src={
                                    value.avatar
                                      ? value.avatar
                                      : 'assets/images/user-100x100.png'
                                  }
                                  alt="avater"
                                  className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                                />
                              </figure>
                              <div className="clearfix w-100"></div>
                              <h4 className="fw-700 font-xsss mt-3 mb-0">
                                {value.username}
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="align-self-center d-flex flex-column justify-content-center align-items-center p-5">
                        <i className="feather-book display5-size text-grey-400"></i>
                        <span className="text-grey-500 m-2 text-center font-md">
                          {i18n.t<string>('Following.noFollowing')}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default Following;
