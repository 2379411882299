import React, { memo, useMemo } from 'react';

import { Modal } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { isMobile } from '../utils/data';

type Props = {
  media: Media[];
  show: boolean;
  onHide: () => void;
  startImageIndex?: number;
  onRemoveImage?: (index: number) => void;
};

const NewImageViewer: React.FC<Props> = ({ show, onHide, media }) => {
  const imagesData = useMemo(() => {
    const images: { original: string; thumbnail: string }[] = [];

    for (const image of media) {
      images.push({ original: image.url, thumbnail: image.url });
    }

    return images;
  }, [media]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header
        style={{
          border: 0,
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <i
          onClick={onHide}
          className="font-xl text-white feather-x"
          style={{
            cursor: 'pointer',
            right: -5,
            zIndex: 2,
            position: 'absolute',
          }}
        />
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        }}
      >
        <ImageGallery
          thumbnailPosition={isMobile() ? 'bottom' : 'left'}
          showFullscreenButton={isMobile() ? false : true}
          lazyLoad={true}
          showPlayButton={false}
          items={imagesData}
          renderRightNav={(onClick) => (
            <i
              onClick={onClick}
              className="font-xxl text-grey-500 feather-chevron-right"
              style={{
                cursor: 'pointer',
                position: 'absolute',
                zIndex: 1,
                right: -5,
                opacity: 0.7,
                alignSelf: 'center',
                backgroundColor: 'white',
                borderRadius: 100,
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
          renderLeftNav={(onClick) => (
            <i
              onClick={onClick}
              className="font-xxl text-grey-500 feather-chevron-left"
              style={{
                cursor: 'pointer',
                position: 'absolute',
                zIndex: 1,
                left: 0,
                opacity: 0.7,
                alignSelf: 'center',
                backgroundColor: 'white',
                borderRadius: 100,
                marginLeft: 25,
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

export default memo(NewImageViewer);
