import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  getAuthSession,
  logout,
  scheduleRefreshToken,
} from '../../utils/session';
import { session } from '../../signals/session';
import { Spinner } from 'react-bootstrap';
import { getProfile } from '../../services/user';
import { profile } from '../../signals/profile';
import { init, initialized } from '../../utils/i18n';

const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const storedAuthSession = getAuthSession();

  if (!storedAuthSession) {
    return <Navigate to="/login" />;
  }

  if (error) {
    return <Navigate to="/error" />;
  }

  const loadUserSession = async () => {
    setLoading(true);

    session.value.token = storedAuthSession.token;
    session.value.refreshToken = storedAuthSession.refreshToken;
    session.value.userId = storedAuthSession.userId;
    session.value.username = storedAuthSession.username;

    try {
      const profileResp = await getProfile();
      profile.value = profileResp;
      scheduleRefreshToken();

      if (!initialized) {
        await init();
      }

    } catch (error) {
      if (!error.response) {
        setError(true);
        return;
      }
      if (error.response.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  if (!session.value.token) {
    loadUserSession();
  }

  if (loading) {
    return (
      <div className="d-flex flex-grow-1 flex-column justify-content-center align-items-center mt-5">
        <Spinner animation="border" role="status" className="text-warning" />
      </div>
    );
  }

  return children;
};

export default ProtectedRoute;
