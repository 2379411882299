import React from 'react';

import { useNavigate } from 'react-router-dom';
import i18n from '../utils/i18n';

const BecomeCreatorBanner = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img
        alt=""
        src="assets/images/become-creator-bg.png"
        style={{
          width: 300,
          height: 300,
          borderRadius: 100,
        }}
      />
      <button
        onClick={() => navigate('/creator')}
        className="form-control text-center text-white fw-600 bg-warning border-0 w-50 p-0 mt-3 mb-5"
      >
        {i18n.t<string>('BecomeCreatorBanner.button')}
      </button>
    </div>
  );
};

export default BecomeCreatorBanner;
