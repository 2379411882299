import axios, { AxiosResponse } from 'axios';
import { session } from '../signals/session';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const getImageUploadUrl = () => {
  return new Promise<string>((resolve, reject) => {
    const { userId, token } = session.value;

    axios
      .get(`${REACT_APP_API_URL}/cf/image-url`, {
        data: { targetUserId: userId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        resolve(response.data.result.uploadURL);
      })
      .catch(function (error) {
        reject(error);
        console.error(error);
      });
  });
};

export const getVideoUploadUrl = () => {
  return new Promise<{ uid: string; uploadURL: string }>((resolve, reject) => {
    const { userId, token } = session.value;

    axios
      .get(`${REACT_APP_API_URL}/cf/video-url`, {
        data: { targetUserId: userId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const { uploadURL, uid } = response.data.result;

        resolve({ uploadURL, uid });
      })
      .catch(function (error) {
        reject(error);
        console.error(error);
      });
  });
};

export const uploadMedia = (url: string, file: any) => {
  return new Promise<UploadMediaResponse>((resolve, reject) => {
    const formData = new FormData();

    formData.append('file', file);

    axios
      .post(url, formData, {
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=---011000010111000001101001',
        },
      })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const post = (
  content: string,
  free: boolean,
  mediaType: string,
  mediaKeys: string[]
) => {
  return new Promise<AxiosResponse>(async (resolve, reject) => {
    const { token } = session.value;

    try {
      const resp = await axios.post(
        `${REACT_APP_API_URL}/post`,
        {
          content,
          free,
          mediaType,
          mediaKeys,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const likePost = (postId: string) => {
  return new Promise(async (resolve, reject) => {
    const { token } = session.value;

    try {
      const resp = await axios.post(
        `${REACT_APP_API_URL}/like`,
        {
          postId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const unlikePost = (postId: string) => {
  return new Promise(async (resolve, reject) => {
    const { token } = session.value;

    try {
      const resp = await axios.delete(`${REACT_APP_API_URL}/like/${postId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const deletePost = (postId: string) => {
  return new Promise(async (resolve, reject) => {
    const { token } = session.value;

    try {
      const resp = await axios.delete(`${REACT_APP_API_URL}/post/${postId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};
