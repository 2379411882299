import React from 'react';
import i18n from '../utils/i18n';

type Props = {
  profile: Profile;
};

const ProfileDetail: React.FC<Props> = ({ profile }) => {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">
          {i18n.t<string>('ProfileDetail.about')}
        </h4>
        <p className="fw-500 lh-24 font-xssss mb-0">{profile.about}</p>
      </div>
    </div>
  );
};

export default ProfileDetail;
